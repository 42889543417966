
console.log("get firebase config", process.env)

const environment = {
  production: false,
  firebase_config: {
    apiKey: 'AIzaSyAgYe-oGt-ItjBp1_dOway3KU1YjeYjKxQ',
    authDomain: 'yin-thway.firebaseapp.com',
    databaseURL: 'https://yin-thway.firebaseio.com',
    projectId: 'yin-thway',
    storageBucket: 'yin-thway.appspot.com',
    messagingSenderId: '133902410612',
    appId: '1:133902410612:web:f0187afcda41f68e814221',
  },

};
export default environment;
